import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId, CountryIdAlpha2 } from '@/domain/models/country'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { TenantId } from '@/domain/models/tenant/tenant-id'

export type StatusWorkflowParams = {
  personId: string
  businessModel?: BUSINESS_MODELS,
  role?: ROLES,
  functionId?: FUNCTION_IDS,
  country?: CountryIdAlpha2
  countryId?: CountryId
  sourceSystem?: string,
  companyId?: CompanyId
  tenantId?: TenantId
}

type StatusWorkflowHeaders = {
    'x-api-key': string,
    businessModel?: BUSINESS_MODELS,
    role?: ROLES,
    function?: FUNCTION_IDS,
    country?: CountryIdAlpha2
    countryId?: CountryId
    sourceSystem?: string,
    companyId?: CompanyId
    tenantId?: TenantId
}

export type ResponseGetStatusWorkflow = {
  status: string
}

export class RemoteGetStatusWorkflow {
    constructor(
        private readonly httpGetClient: HttpGetClient<StatusWorkflowHeaders, StatusWorkflowParams>,
        private readonly xApiKey: string,
    ){}

    get = async (params : StatusWorkflowParams): Promise<ResponseGetStatusWorkflow> => {
        const {
          businessModel,
          role,
          functionId,
          country,
          countryId,
          sourceSystem,
          companyId,
          tenantId
        } = params

        const httpResponse = await this.httpGetClient.get({
          url: `/address/status-workflow/${params.personId}`,
          headers: {
            'x-api-key': this.xApiKey,
            'businessModel': businessModel,
            'role': role,
            'function': functionId,
            'country': country,
            'countryId': countryId,
            'sourceSystem': sourceSystem,
            'companyId': companyId,
            'tenantId': tenantId,
          },
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
              return httpResponse.body
            case HttpStatusCode.noContent:
                return httpResponse.body
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
