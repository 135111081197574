import React, { useContext } from 'react'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import {
  StatusCardContainer,
} from '@/main/components/status-card-container'
import { MaintenanceAddressCompletedMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { Button } from '@naturacosmeticos/natds-react'
import { IdentityInfoContext } from '@/main/contexts'
import { getObjectInStorage, removeItemInStorage } from '@/main/hooks/useStorage'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AddressStorage, ADDRESS_STORAGE_NAME, CHANNEL_STORAGE_NAME } from '@/domain/models/address'
import {
  SuccessTitle,
  Divider,
  DescriptionContainer,
  ContentSuccess,
  Text,
  Subtitle,
  ActionContainer,
  StatusContainer,
  InfoContainer,
} from './register-maintenance-address-approved.styles'

export const MaintenanceAddressApproved: React.FC = () => {
  const { personId, companyId, countryId } = useContext(IdentityInfoContext)

  const addressStorage = getObjectInStorage(ADDRESS_STORAGE_NAME) as AddressStorage

  const messages = usePageMessages(
    Page.MaintenanceAddressApproved
  ).messages as MaintenanceAddressCompletedMessages
  
  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]
  const { zipCode } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs

  const redirectToProfile = () => {
    removeItemInStorage(ADDRESS_STORAGE_NAME)
    removeItemInStorage(CHANNEL_STORAGE_NAME)
    window.location.assign(`/webfv/mfe-register/profile/${personId}`)
  }
  
  const address = {
    zipCode: `${addressStorage?.address?.zipCode?.replace(zipCode.regex, zipCode.regexOption) || ''}`,
    streetNumberComplement: `${addressStorage?.address.street || ''}, ${addressStorage?.address.number || ''}, ${addressStorage?.address.complement || ''}`,
    neighborhoodCityState: `${addressStorage?.address.neighborhood || ''}, ${addressStorage?.address.city || ''}, ${addressStorage?.address.state || ''}`,
    refences: `${addressStorage?.address.references || ''}`
  }

  return (
    <StatusCardContainer>
      <ContentSuccess>
        <img
          alt={messages.approvedIcon}
          src={ApprovedIcon}
          aria-label="approved-image"
          data-testid="approved-image"
        />
        <SuccessTitle variant="h5" className="success_title">{messages.title}</SuccessTitle>
        <Subtitle>
          <Text align="center" variant="subtitle1">{messages.subtitle}</Text>
        </Subtitle>
        <div>
          <Divider />
          <Text align="center" variant="body1" color="textSecondary">{messages.description}</Text>
        </div>
        <DescriptionContainer>
          <InfoContainer>
            <Text variant="body2" className="label" color="textSecondary">{messages?.addressLabel}</Text>
            <Text variant="h6">{address.zipCode}</Text>
            <Text variant="h6">{address.streetNumberComplement}</Text>
            <Text variant="h6">{address.neighborhoodCityState}</Text>
            <Text variant="h6" className="reference">{address.refences}</Text>
          </InfoContainer>
          <StatusContainer>
            <Text className="label" variant="body2" color="textSecondary">{messages.statusLabel}</Text>
            <Text className="successColor" variant="body2">APROVADO</Text>
          </StatusContainer>
        </DescriptionContainer>
      </ContentSuccess>
      <ActionContainer>
        <Button onClick={redirectToProfile}>{messages.myProfileButton}</Button>
      </ActionContainer>
    </StatusCardContainer>
  )
}
