import React, { useContext }  from 'react'
import { Button } from '@naturacosmeticos/natds-react'
import { IdentityInfoContext } from '@/main/contexts'
import RejectedIcon from '@/main/assets/svgs/rejected_icon.svg'
import { MaintenanceAddressRejectedMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import {
    StatusCardContainer,
  } from '@/main/components/status-card-container'
import { ADDRESS_STORAGE_NAME, CHANNEL_STORAGE_NAME } from '@/domain/models/address'
import { removeItemInStorage } from '@/main/hooks/useStorage'
import {
    InfoContainer,
    DescriptionContainer,
    Title,
    Text,
    ContentRejected,
    ActionContainer
} from './register-maintenance-address-rejected.styles'

export const MaintenanceAddressRejected: React.FC = () => { 
    const { personId } = useContext(IdentityInfoContext)

    const messages = usePageMessages(
        Page.MaintenanceAddressRejected
      ).messages as MaintenanceAddressRejectedMessages

    const redirectToProfile = () => {
      removeItemInStorage(ADDRESS_STORAGE_NAME)
      removeItemInStorage(CHANNEL_STORAGE_NAME)
      window.location.assign(`/webfv/mfe-register/profile/${personId}`)
    }

    return (
      <StatusCardContainer>
        <ContentRejected>
          <img
            alt={messages.rejectededIcon}
            src={RejectedIcon}
            aria-label="rejected-image"
            data-testid="rejected-image"
          />
          <Title>
            <Text align="center" variant="h5">{messages.title}</Text>
          </Title>
          <DescriptionContainer>
            <InfoContainer>
              <Text align="center" variant="subtitle1">{messages.description}</Text>
            </InfoContainer>
          </DescriptionContainer>
        </ContentRejected>
        <ActionContainer>
          <Button onClick={redirectToProfile}>{messages.myProfileButton}</Button>
        </ActionContainer>
      </StatusCardContainer>  
    )
}