import React, { useContext, useState } from 'react'
import { MaintenanceValidationCodeMessages } from '@/domain/models/messages/messages'
import { Container } from '@/main/components/pages-structures'
import { ControllerProps, FormProvider, useForm } from 'react-hook-form'
import { Typography, Spacing } from '@naturacosmeticos/natds-web'
import { CountdownLink } from '@/main/components/countdown-link/countdown-link'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { IdentityInfoContext } from '@/main/contexts'
import { getObjectInStorage } from '@/main/hooks/useStorage'
import { AddressStorage, ADDRESS_STORAGE_NAME, ChannelStorage, CHANNEL_STORAGE_NAME } from '@/domain/models/address'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { ValidationCodePageApi } from './api/make-maintenance-validation-code-page-api'
import { ControlledTextField } from './components/controlled-text-field'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { ADD_QUANTITY_REQUESTS, APPROVED_STATUS, DELAY_TO_REQUEST_STATUS_WORKFLOW, MAX_LIMIT_RETRY_STATUS, REJECTED_STATUS, RUNNING_STATUS, TIME_TO_RETRY_STATUS } from './utils/constants'

type FormInputs = {
  code: string,
}

type CodeInputProps = {
  validChars: RegExp
} & Pick<ControllerProps<'input'>, 'rules'>

export type ValidationCodePageProps = {
  api: ValidationCodePageApi
}



export const MaintenanceValidationCodePage  = ({api}: ValidationCodePageProps) => {
  const [disableNextButton, setDisableNextButton] = useState<boolean>(true)
  const [inputValidation, setInputValidation] = useState<string>()
  const [infoMessage, setInfoMessage] = useState('')
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)

  const [quantityRetry, setQuantityRetry] = useState(0)
  
  const { personId, countryId, companyId } = useContext(IdentityInfoContext)
  
  const messages = usePageMessages(
    Page.MaintenanceValidationCode
  ).messages as MaintenanceValidationCodeMessages 

  const codeInput: CodeInputProps = {
    rules: {
      required: true,
      minLength: {
        value: 6,
        message: messages.errorMessageValidationCode
      },
      pattern: {
        value: new RegExp(/^\d{0,6}$/),
        message: messages.errorMessageValidationCode
      },
    },
    validChars: new RegExp(/^\d{0,6}$/)
  } 

  const formMethods = useForm<FormInputs>({
    mode: 'onChange',
    defaultValues: {
      code: ''
    }
  })

  const { watch, control } = formMethods

  const goRejectedPage = ()=> window.location.assign(`/webfv/mfe-register/maintenanceAddressRejected/${personId}`)

  const goApprovedPage = ()=> window.location.assign(`/webfv/mfe-register/maintenanceAddressApproved/${personId}`)

  const goBackPreviousPage = () => window.location.assign(`/webfv/mfe-register/maintenanceSecurityValidation/${personId}`)

  const resendValidationCodeHandler = async (): Promise<void> => {
    window.location.assign(`/webfv/mfe-register/maintenanceSecurityValidation/${personId}`)
  }
  
  const handleSubmit = async () => {
    try {
      setOpenSubmitLoading(true)
      
      const addressStorage = getObjectInStorage(ADDRESS_STORAGE_NAME) as AddressStorage
      await api.saveAddress({
        address: addressStorage.address, countryId, companyId, personId, functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
        role: ROLES.CONSULTANT, businessModel: BUSINESS_MODELS.DIRECT_SALE, sourceSystem: '99'
      })

      setTimeout(() => {
        getStatusWorkflow()
      }, DELAY_TO_REQUEST_STATUS_WORKFLOW)
    
    } catch (err) {
    goRejectedPage()
    } 
  }

  const checkCode = async (code: string) => {
    try {
      setOpenSubmitLoading(true)
      const { channel, sequence } = getObjectInStorage(CHANNEL_STORAGE_NAME) as ChannelStorage
      await api.checkValidationCode({personId, countryId, companyId, verificationCode: code, channel, sequence})
      setDisableNextButton(false)
      setInfoMessage(messages.successMessageValidationCode)
      setInputValidation('success')
      setOpenSubmitLoading(false)
    } catch {
      setDisableNextButton(true)
      setInfoMessage(messages.errorMessageValidationCode)
      setInputValidation('error')
      setOpenSubmitLoading(false)
    }
  }

  const getStatusWorkflow = async () => {
    if (quantityRetry === MAX_LIMIT_RETRY_STATUS)
     goRejectedPage()
    try {
      const response =  await api.statusWorkflow({
        personId,
        businessModel: BUSINESS_MODELS.DIRECT_SALE,
        role: ROLES.CONSULTANT,
        functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
        countryId,
        sourceSystem: '99',
        companyId,
      })
      switch (response.status) { 
        case APPROVED_STATUS: { 
          goApprovedPage()
          break 
        } 
        case REJECTED_STATUS: { 
           goRejectedPage()
          break 
        } 
        case RUNNING_STATUS: { 
          setTimeout(() => { 
            setQuantityRetry(prev=> prev + ADD_QUANTITY_REQUESTS )
            getStatusWorkflow()
          }, TIME_TO_RETRY_STATUS)
         break 
       } 
        default: { 
          goRejectedPage()
          break 
        } 
      } 
    } catch {
     goRejectedPage()
    }
  }
  
  const handleChange = () => {
    const codeValue = watch('code')
    if (codeValue.length === 6) {
      checkCode(codeValue)
    } else {
      setDisableNextButton(true)
      setInfoMessage(messages.errorMessageValidationCode)
      setInputValidation('error')
    }
  }
  
  return (
    <Container
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleSubmit}
      disableNextButton={disableNextButton}
      previousButtonLabel={messages.backButtonLabel}
      onPreviousButtonClick={goBackPreviousPage}
    >
      <Typography variant="h5">{messages.title}</Typography>
      <Spacing padding="tiny" />
      <Typography variant="body1">{messages.description}</Typography>
      <Spacing padding="standard" />
      <FormProvider {...formMethods}>
        <ControlledTextField
          customOnChange={handleChange}
          infoMessage={infoMessage}
          state={inputValidation}
          label={messages.inputValidationCodeLabel}
          id="code"
          data-testid="validation-code-input-field-test-id"
          rules={codeInput.rules}
          validChars={codeInput.validChars}
          control={control}
        />
      </FormProvider>
      <Spacing paddingTop="small">
        <CountdownLink
          countdown={300}
          timerMessage={messages.resendCodeMessage}
          linkText={messages.resendLink}
          onClick={resendValidationCodeHandler}
        />
      </Spacing>
      <Spacing padding="small" />
      <Typography variant="subtitle1">
        <ParseStringToReact stringToParse={messages.notReceivedTitle} />
      </Typography>
      <Spacing padding="tiny" />
      <Typography variant="body1">
        <ParseStringToReact stringToParse={messages.contactInformationText} />
      </Typography>
      <DialogWithLoader isOpen={openSubmitLoading} title={messages.waitingMessage} />
    </Container>
  )
}
