import React, { useContext, useEffect } from 'react'
import {
  IdentityInfoContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import {
  Spacing, Typography, Divider, GridContainer, GridItem,
} from '@naturacosmeticos/natds-web'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import {
  PreRegisterConfirmationMessages,
} from '@/domain/models/messages/messages'

import {
  CheckIcon,
  SuccessText,
} from '@/main/pages/pre-register-confirmation/pre-register-confirmation-page.styles'
import { CountdownLink } from '@/main/components/countdown-link/countdown-link'
import { PreRegisterConfirmationApi } from '@/main/pages/pre-register-confirmation/api/make-pre-register-confirmation-api'

type PreRegisterConfirmationProps = {
  api: PreRegisterConfirmationApi
}

export const PreRegisterConfirmationPage: React.FC<PreRegisterConfirmationProps> = ({ api }) => {
  const messages = usePageMessages(Page.PreRegisterConfirmation)
    .messages as PreRegisterConfirmationMessages

  const { tenantId, personId } = useContext(IdentityInfoContext)

  const {
    goToPreviousPage,
  } = useContext<NavigationContextProps>(NavigationContext)

  const goToReportsPage = () => {
    window.location.replace(process.env.REPORTS_PAGE)
  }

  const onResendLink = async () => {
    try {
      await api.sendLink({ tenantId, personId })
    } catch (err) {
      console.warn(err)
    }
  }

  useEffect(() => {
    onResendLink().catch((err) => {
      console.warn(err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Container
        previousButtonLabel={messages.previousButtonLabel}
        onPreviousButtonClick={goToPreviousPage}
        nextButtonLabel={messages.nextButtonLabel}
        onNextButtonClick={goToReportsPage}
      >
        <GridContainer direction="column" alignItems="center">
          <CheckIcon
            data-testid="confirmation-icon"
            name="filled-action-check"
            size="medium"
          />

          <GridItem xs={10} sm={7} md={7} lg={7}>
            <Typography variant="h6" align="center">
              <Spacing my="small" px="semiX">
                <SuccessText>
                  {messages.title}
                </SuccessText>
              </Spacing>
            </Typography>
          </GridItem>

          <GridItem xs={10}>
            <Typography variant="subtitle2" align="center">
              {messages.subtitle}
            </Typography>

            <Spacing my="standard">
              <Divider />
            </Spacing>
          </GridItem>

          <GridItem xs={10}>
            <Spacing mb="small">
              <Typography variant="body2">
                {messages.instructions.aboutLink}
              </Typography>
            </Spacing>

            <Typography variant="body2">
              {messages.instructions.aboutResending}
            </Typography>

            <Spacing mt="standard">
              <CountdownLink
                countdown={30}
                timerMessage={messages.timerMessage}
                linkText={messages.resendLink}
                onClick={onResendLink}
              />
            </Spacing>
          </GridItem>
        </GridContainer>
      </Container>
    </>
  )
}
