import React, {
  useContext, useEffect, useState,
} from 'react'
import { Container } from '@/main/components/pages-structures'
import { Icon, GridContainer, GridItem, Typography } from '@naturacosmeticos/natds-web'
import Alert from '@naturacosmeticos/natds-react/dist/components/Alert'
import { Checkbox } from '@/main/components/checkbox'
import { FormProvider, useForm } from 'react-hook-form'
import { IdentityInfoContext } from '@/main/contexts'
import { Page } from '@/domain/models/page/page'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { MaintenanceAddressMessages } from '@/domain/models/messages/messages'
import { validateStructureCodeSegmentation } from '@/domain/models/tenant/tenant-configs/helpers/validate-structure-code-segmentation'
import { ControlledAddressNumberField, ControlledSelect, ControlledTextField } from '@/main/components'
import { LevelName } from '@/domain/models/address/address-levels'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { ADDRESS_DELIVERY_TYPE } from '@/domain/models/address/address-delivery-type'
import { redirectToProfile } from '@/domain/models/tenant/tenant-configs/helpers/redirect'
import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { AddressSavePageForms } from '@/data/use-cases/register-maintenance/remote-save-address'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { HttpStatusCode } from '@/data/protocols/http'
import { getObjectInStorage, removeItemInStorage, setObjectInStorage } from '@/main/hooks/useStorage'
import { AddressStorage, ADDRESS_STORAGE_NAME } from '@/domain/models/address'
import { AlertContainer, useMaintenanceAddressPage, StreetExample } from './maintenance-address-page.styles'
import { AddressMessages } from '../address/models'
import { RegisterMaintenanceAddressPageApi } from './api/make-register-maintenance-address-page-api'
import { MaintenanceDialog } from '../register-maintenance/fields/maintenance-dialog'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { ControlledTextFieldWithLink } from './components/text-field-with-link/controlled-text-field-with-link'

export type MaintenanceAddressPageProps = {
  api: RegisterMaintenanceAddressPageApi
}

type DialogState = {
  message?: string
  isOpen: boolean
  confirmButtonName?: string
  cancelButtonName?: string
  confirmationButtonCallback?: () => void
  cancelButtonCallback?: () => void
  component?
}

type FormAddress = {
  city: string
  complement: string
  neighborhood: string
  number: string
  references: string
  state: string
  street: string
  zipCode: string
}

type AddressFieldStatus = {
  isStreetDisabled: boolean
  isNeighborhoodDisabled: boolean
}

export const MaintenanceAddressPage: React.FC<MaintenanceAddressPageProps> = ({ api }) => {

  const {
    personId,
    countryId,
    companyId,
    businessModel
  } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const componentClasses = useMaintenanceAddressPage()

  const addressMessages = usePageMessages(Page.Address).messages as AddressMessages

  const [deliveryTypeLabel, setDeliveryTypeLabel] = useState<string>('')

  const createMessageOptions = (label) => ({ addressDeliveryType: label })
  const maintenanceAddressMessages = usePageMessages(Page.MaintenanceAddress, createMessageOptions(deliveryTypeLabel)).messages as MaintenanceAddressMessages

  const formMethods = useForm<FormAddress>({
    mode: 'onChange',
  })

  const [addressModalStatus, setAddressModalStatus] = useState<DialogState>({ message: '', isOpen: false })
  const [openLoadingSpinner, setOpenLoadingSpinner] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [savedAddress, setSavedAddress] = useState<AddressPageForms>()
  const [receiveBillingAtSameAddress, setReceiveBillingAtSameAddress] = useState<boolean>(false)
  const [addressFieldsStatus, setAddressFieldsStatus] = useState<AddressFieldStatus>({ isStreetDisabled: false, isNeighborhoodDisabled: false,  })

  const { zipCode, number } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs

  const formRules = { required: addressMessages.addressRequiredError }

  const {
    getValues,
    control,
    reset,
    setError,
    formState: { isValid, isDirty },
  } = formMethods

  const getDeliveryTypeAddress = (deliveryType: string) => {
    let deliveryName

    switch (ADDRESS_DELIVERY_TYPE[deliveryType]) {
      case ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.delivery
        break
      case ADDRESS_DELIVERY_TYPE.MAILING_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.mailing
        break
      case ADDRESS_DELIVERY_TYPE.BILLING_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.billing
        break
      default:
        deliveryName = ''
    }

    return deliveryName
  }

  useEffect(() => {
    if (!validateStructureCodeSegmentation()) {
      redirectToProfile(personId)
    }
    setAddressModalStatus({
      isOpen: true,
      component: () => leaderChangeAlert(componentClasses, maintenanceAddressMessages.leaderDialog),
      confirmButtonName: maintenanceAddressMessages.leaderDialog.confirmButton,
      cancelButtonName: maintenanceAddressMessages.leaderDialog.cancelButton,
      confirmationButtonCallback: onCloseWithoutPreviousPage,
      cancelButtonCallback: onClose
    })
    setIsLoading(false)
  }, [api, companyId, countryId, personId, reset])

  const getAddressStorage = () => {
    const addressStorage = getObjectInStorage(ADDRESS_STORAGE_NAME) as AddressStorage
    if (addressStorage && addressStorage?.personId === personId) {
      return { ...addressStorage.address }
    }
    removeItemInStorage(ADDRESS_STORAGE_NAME)
    return {}

  }

  const loadAddressApi = async () => {
    setOpenLoadingSpinner(true)
    api.getMultipleAddresses({ personId, countryId, companyId })
      .then(({deliveryAddress}) => {
        const addressStorage = getAddressStorage()
        const address = { ...deliveryAddress, ...addressStorage }
        setDeliveryTypeLabel(getDeliveryTypeAddress(ADDRESS_DELIVERY_TYPE[address.addressUse]))
        setSavedAddress(address)

        api.getAddressByZipCode({
          businessModel,
          code: Number(getOnlyNumbers(address.zipCode)),
          tenantId,
        }).then((addressResponse) => {
          setAddressFieldsStatus({
            isStreetDisabled: addressResponse.street !== '',
            isNeighborhoodDisabled: addressResponse.neighborhood !== '',
          })

          reset({
            city: address.city,
            complement: address.complement,
            neighborhood: address.neighborhood,
            number: address.number || maintenanceAddressMessages.withoutNumber,
            references: address.references,
            state: address.state,
            street: address.street,
            zipCode: address.zipCode.replace(zipCode.regex, zipCode.regexOption),
          })
        }).catch((error) => {
          handleDataCareException(error)
        }).finally(() => {
          setOpenLoadingSpinner(false)
        })
      }).catch(() => {
        console.warn('Error on get addresses')
      })
      .finally(() => {
        setOpenLoadingSpinner(false)
      })
  }

  const handleGetAddressByZipCode = async (zipCodeValue) => {
    const isValidZipCodeLength = getOnlyNumbers(zipCodeValue)?.length === zipCode.rules.maxLength -1

    if (isValidZipCodeLength) {
      setOpenLoadingSpinner(true)
      try {
        const addressResponse = await api.getAddressByZipCode({
          businessModel,
          code: Number(getOnlyNumbers(zipCodeValue)),
          tenantId,
        })

        setAddressFieldsStatus({
          isStreetDisabled: addressResponse.street !== '',
          isNeighborhoodDisabled: addressResponse.neighborhood !== '',
        })

        reset({
          city: addressResponse.city,
          neighborhood: addressResponse.neighborhood,
          state: addressResponse.state,
          zipCode: zipCodeValue,
          street: addressResponse.street,
          references: '',
          number: '',
          complement: '',
        })
      } catch (error) {
        handleDataCareException(error)
      } finally {
        setOpenLoadingSpinner(false)
      }
    } else {
      setError(LevelName.ZipCode, {type: 'error', message: addressMessages.zipCode.errorMessage})
      
    }
  }

  const handleDataCareException = (error) => {
    if (error.statusCode === HttpStatusCode.notFound) {
      handleNotFoundZipCode()
    } else {
      setAddressModalStatus({
        message:
        actionMessages.unexpectedErrorDialogMessage,
        isOpen: true,
        confirmationButtonCallback: onCloseWithoutPreviousPage,
        confirmButtonName: actionMessages.dialogButtonName
      })
    }
  }

  const handleNotFoundZipCode = () => {
    reset({
      ...getValues(),
      city: '',
      neighborhood: '',
      state: '',
      street: '',
      references: '',
      number: '',
      complement: '',
    })
    
    setError(LevelName.ZipCode, {type: 'error', message: addressMessages.zipCode.notFoundZipCode})
  }

  const actionMessages = {
    saveButton: maintenanceAddressMessages.labels.saveButton,
    previousButton: maintenanceAddressMessages.labels.previousButton,
    dialogButtonName: maintenanceAddressMessages.dialog.buttonName,
    unexpectedErrorDialogMessage: maintenanceAddressMessages.dialog.unexpectedErrorMessage,
    successDialogMessage: maintenanceAddressMessages.dialog.successMessage,
  }

  const goBackPreviousPage = () => (window.location.assign(`/webfv/mfe-register/profile/${personId}`))

  const onClose = () => {
    setAddressModalStatus({ isOpen: false })
    goBackPreviousPage()
  }

  const onCloseWithoutPreviousPage = () => {
    loadAddressApi()
    setAddressModalStatus({ isOpen: false })
  }

  const mapToAddressPageForms = (address: FormAddress): AddressSavePageForms => {
    return {
      city: address.city,
      complement: address.complement,
      country: savedAddress.country,
      number: address.number,
      neighborhood: address.neighborhood,
      references: address.references,
      state: address.state,
      street: address.street,
      zipCode: getOnlyNumbers(address.zipCode),
      addressType: savedAddress.addressType,
      addressUse: savedAddress.addressUse,
      sequence: savedAddress.sequence,
      receiveBillingAtSameAddress,
    }
  }

  const handleSubmit = () => {
    const addressStorage = {
      personId,
      address: mapToAddressPageForms(getValues())
    }

    setObjectInStorage(ADDRESS_STORAGE_NAME, addressStorage)
    window.location.assign(`/webfv/mfe-register/maintenanceSecurityValidation/${personId}`)
  }
  
  return (
    <Container
      nextButtonLabel={actionMessages.saveButton}
      disableNextButton={!isValid || !isDirty}
      onNextButtonClick={handleSubmit}
      previousButtonLabel={actionMessages.previousButton}
      onPreviousButtonClick={goBackPreviousPage}
      nextButtonTextInline
      isLoading={isLoading}
    >
      <div className={componentClasses.container}>
        <Typography
          variant="h6"
          color="textPrimary"
          className={componentClasses.title}
        >
          <span data-testid={deliveryTypeLabel}>
            {maintenanceAddressMessages.address}
          </span>
        </Typography>
        <FormProvider {...formMethods}>
          {zipCode && (
            <ControlledTextFieldWithLink
              id={LevelName.ZipCode}
              label={addressMessages.zipCode.label}
              placeholder={addressMessages.zipCode.placeholder}
              linkText={zipCode.shouldDisplayLink({authenticated: true}) ? addressMessages.zipCode.link.text : undefined}
              href={zipCode.shouldDisplayLink({authenticated: true}) ? addressMessages.zipCode.link.url : undefined}
              control={control}
              customOnChange={handleGetAddressByZipCode}
              mask={zipCode.mask}
              rules={{
                minLength: {
                  message: addressMessages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                maxLength: {
                  message: addressMessages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                required: {
                  value: true,
                  message: addressMessages.zipCode.requiredErrorMessage,
                },
              }}
              required
              showAsterisk
            />
          )}
          <ControlledTextField
            id={LevelName.Street}
            label={addressMessages.mainStreet}
            control={control}
            rules={formRules}
            placeholder={addressMessages.placeholders.mainStreet}
            required
            disabled={addressFieldsStatus.isStreetDisabled}
            shouldValidateIsNotOnlySpaces
          />
          {!addressFieldsStatus.isStreetDisabled && (
            <StreetExample>
              <Typography variant="caption" className={componentClasses.streetExample}>
                {maintenanceAddressMessages.streetExample}
              </Typography>
            </StreetExample>
          )} 
          <GridContainer className="natds1" justify="space-between" spacing={3}>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <div className={componentClasses.addressNumberField}>
                <ControlledAddressNumberField
                  id={LevelName.Number}
                  label={addressMessages.number}
                  placeholder={addressMessages.placeholders.number}
                  rules={{
                    required: {
                      message: addressMessages.addressRequiredError,
                      value: true,
                    },
                  }}
                  noNumberLabel={addressMessages.checkBoxWithoutNumber}
                  validChars={number.validChars}
                  hasNoNumberCheckbox={number?.hasNoNumberCheckbox}
                />
              </div>
            </GridItem>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <ControlledTextField
                id={LevelName.Complement}
                label={addressMessages.complementaryData}
                control={control}
                placeholder={addressMessages.placeholders.complementaryData}
              />
            </GridItem>
          </GridContainer>
          <ControlledTextField
            id={LevelName.Neighborhood}
            label={addressMessages.neighborhood}
            control={control}
            rules={formRules}
            placeholder={addressMessages.placeholders.neighborhood}
            required
            shouldValidateIsNotOnlySpaces
            disabled={addressFieldsStatus.isNeighborhoodDisabled}
          />
          <GridContainer className="natds1" justify="space-between" spacing={3}>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <ControlledTextField
                id={LevelName.City}
                label={addressMessages.city}
                control={control}
                rules={formRules}
                placeholder={addressMessages.placeholders.city}
                required
                shouldValidateIsNotOnlySpaces
                disabled
              />
            </GridItem>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <ControlledTextField
                id={LevelName.State}
                label={addressMessages.state}
                control={control}
                rules={formRules}
                placeholder={addressMessages.placeholders.state}
                required
                shouldValidateIsNotOnlySpaces
                disabled
              />
            </GridItem>
          </GridContainer>
          <ControlledTextField
            id={LevelName.References}
            label={addressMessages.references}
            control={control}
            rules={formRules}
            placeholder={addressMessages.placeholders.references}
            required
            shouldValidateIsNotOnlySpaces
          />
          <div className={componentClasses.displayNone}>
            <ControlledSelect
              id={LevelName.addressDeliveryType}
              label={maintenanceAddressMessages.labels.addressDeliveryType}
              aria-label={maintenanceAddressMessages.labels.addressDeliveryType}
              rules={formRules}
              control={control}
              options={[{ label: addressMessages.addressDeliveryType.delivery, value: ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS }]}
              disabled
              defaultValue={{ label: addressMessages.addressDeliveryType.delivery, value: ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS }}
              hasAsteriskInTheLabel
            />
          </div>
          <div className={componentClasses.displayNone}>
            <Checkbox
              color="secondary"
              label={addressMessages.confirmDeliveryAddressAsResidenceAddress}
              checked={receiveBillingAtSameAddress}
              onChange={(e) => setReceiveBillingAtSameAddress(e.target.checked)}
            />
          </div>
        </FormProvider>
        <AlertContainer data-testid="alert-container">
          <Alert title={maintenanceAddressMessages.alert.title} color='warning' type='outlined' data-testid="alert-component">
            <Typography 
              variant="body2"
              data-testid="alert-subtitle-one"
              className={componentClasses.alertSpace}
            >
              {maintenanceAddressMessages.alert.subtitleOne}
            </Typography>
            <Typography 
              variant="body2"
              className={componentClasses.alertSpace}
            >
              {maintenanceAddressMessages.alert.subtitleTwo}
            </Typography>
          </Alert>
        </AlertContainer>
        <MaintenanceDialog
          message={addressModalStatus.message}
          confirmButtonName={addressModalStatus.confirmButtonName}
          cancelButtonName={addressModalStatus.cancelButtonName}
          open={addressModalStatus.isOpen}
          onClickConfirmButton={addressModalStatus.confirmationButtonCallback}
          onClickCancelButton={addressModalStatus.cancelButtonCallback}
          Children={addressModalStatus.component}
        />         
        <DialogWithLoader isOpen={openLoadingSpinner} />
      </div>
    </Container>
  )
}

const leaderChangeAlert = (componentClasses, messages) => (
  <div className={componentClasses.dialogLeader}>
    <Typography variant="h6" className={componentClasses.dialogLeaderTitle}>
      {messages.title}
    </Typography>
    <span className={componentClasses.dialogLeaderItem}>
      <Icon
        data-testid="status-icon"
        name="outlined-action-requestspecial"
        size="tiny"
        className={componentClasses.dialogLeaderIcon}
      />
      <Typography variant="subtitle1">
        {messages.alertRequestProcess}
      </Typography>
    </span>
    <span className={componentClasses.dialogLeaderItem}>
      <Icon
        data-testid="status-icon"
        name="outlined-social-digitalconsultant"
        size="tiny"
        className={componentClasses.dialogLeaderIcon}
      />
      <Typography variant="subtitle1">
        {messages.leaderChange}
      </Typography>
    </span>
    <Typography variant="subtitle1">
      {messages.confirmation}
    </Typography>
  </div>
)
