import { createPhoneNumberFirstDigitValidation } from '@/main/pages/personal-data/fields'
import {
  AddressConfigs,
  AttachmentsConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { Document } from '../../documents/documents'
import { AttachmentType } from '../../attachments/attachments'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { VendorId } from '../../vendor/vendor-id'
import { AddressPageType, AddressOrder } from '../../address'
import { PhoneType } from '../../person'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.DniPeru,
      name: 'DNI',
      placeholder: 'DNI',
      validChars: /^[\d]{0,8}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 8,
        maxLength: 8,
      },
    },
    {
      type: Document.ForeignRegistry,
      name: 'Carnet de Extranjeria',
      placeholder: 'CE',
      validChars: /^[\d]{0,9}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 9,
        maxLength: 9,
      },
    },
    {
      type: Document.PassportPeru,
      name: 'Pasaporte',
      placeholder: 'pasaporte',
      validChars: /^[\d]{0,9}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 9,
        maxLength: 9,
      },
    },
  ],
  enableIndicationLink: true,
}

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.MERCADO_PAGO,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType: AddressPageType.GeographicalLevels,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 3 },
  number: {
    validChars: null,
    hasNoNumberCheckbox: false,
  },
  reference: {},
  showDeliveryCheckbox: false,
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.DniPeru,
      name: 'DNI',
      attachments: [
        { title: 'Frente', type: AttachmentType.DniPeruFront },
        { title: 'Dorso', type: AttachmentType.DniPeruBack },
      ],
    },
    {
      type: Document.ForeignRegistry,
      name: 'Carnet de Extranjeria',
      attachments: [
        { title: 'Frente', type: AttachmentType.ForeignCarnetFront },
        { title: 'Dorso', type: AttachmentType.ForeignCarnetBack },
      ],
    },
    {
      type: Document.PassportPeru,
      name: 'Pasaporte',
      attachments: [
        { title: 'Frente', type: AttachmentType.PassportFront },
        { title: 'Dorso', type: AttachmentType.PassportBack },
      ],
    },
  ],
  canChooseDocument: false,
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: true,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  goToClickButton: {
    show: false,
  },
  companyIconName: companyIconName[CompanyName.Natura]
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = null

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/],
      firstDigitValidation: createPhoneNumberFirstDigitValidation('9'),
      length: 9,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 8,
    },
  ],
  shouldUseSeparatedNames: process.env.SHOULD_USE_SEPARATED_NAMES === 'true',
  namesOptions: ['firstName', 'lastName'],
  allocationStrategy: { levels: { length: 4 } },
}

const profile: ProfileConfigs = null

export const naturaPerConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  address,
  digitalStore,
  digitalSignaturePage: null,
  completed,
  authorizationCode: null,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
