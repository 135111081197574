import React from 'react'
import { ApprovedMessages } from '@/main/pages/confirmation/approved/approved-wrapper'
import {
  DescriptionTexts,
} from '@/main/pages/confirmation/status-card.styles'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import { Typography, Link } from '@naturacosmeticos/natds-web'
import {
  SuccessTitle,
  Divider,
  Number,
  DescriptionContainer,
  StepContainer,
  StepContent,
  StepTitle,
  ContentSuccess,
} from '@/main/pages/confirmation/success-status-card.styles'
import AppStoreIcon from '@/main/assets/svgs/per_app_store'
import PlayStoreIcon from '@/main/assets/svgs/per_google_play'
import {
  colorFromStatus,
  StatusCardContainer,
} from '@/main/components/status-card-container'
import {
  ConsultantCode,
  ConsultantInfoContainer,
  Footer,
  FooterDescripton,
  IndicationContainer,
  PaymentInfo,
  AppBadgeWrapper,
  DigitalStoreContainer,
  DigitalStoreDescription,
} from '@/main/pages/confirmation/approved/approved.styles'
import { TypographyWithHTMLParser } from '@/main/components/string-to-react'
import { ImageDynamicLoad } from './image-dynamic-load'

type ApprovedProps = {
  messages: ApprovedMessages,
  approvedCandidateId?: string,
  indicationName?: string,
  steps?: string[],
  paymentPlanKey?: string,
  hasDigitalStore?: boolean,
}

export const Approved: React.FC<ApprovedProps> = ({
  messages,
  approvedCandidateId,
  indicationName,
  steps,
  paymentPlanKey,
  hasDigitalStore,
}) => (
  <StatusCardContainer status={colorFromStatus.SUCCESS}>
    <ContentSuccess>
      <Divider>
        <img
          alt={messages.iconAlts.approved}
          src={ApprovedIcon}
          aria-label="approved-image"
          data-testid="approved-image"
        />
        <SuccessTitle variant="h5" className="success_title">{messages.title}</SuccessTitle>
        <DescriptionContainer>
          <DescriptionTexts className="bold" variant="body1">{messages.subtitle}</DescriptionTexts>
          <DescriptionTexts variant="body1" color="textSecondary">{messages.description}</DescriptionTexts>
        </DescriptionContainer>
      </Divider>

      <Divider>
        <ConsultantInfoContainer>
          <Typography variant="body2" color="textSecondary">{messages.consultantCodeLabel}</Typography>
          <ConsultantCode className="bold consultant_code" variant="h6">{approvedCandidateId}</ConsultantCode>
          <PaymentInfo variant="caption" color="textSecondary" className="payment_info">
            {messages.paymentLabel}
            {' '}
            <strong>{messages.paymentPlan[paymentPlanKey]}</strong>
          </PaymentInfo>

          {indicationName && (
            <IndicationContainer>
              <DescriptionTexts color="textSecondary" variant="body2">{messages.indicationLabel}</DescriptionTexts>
              <DescriptionTexts className="bold" variant="subtitle2">{indicationName}</DescriptionTexts>
            </IndicationContainer>
          )}
        </ConsultantInfoContainer>
      </Divider>

      {hasDigitalStore
        && (
          <DigitalStoreContainer>
            <StepContent>
              <StepTitle variant="h6" className="step_title">{messages.digitalStore.title}</StepTitle>
              <DigitalStoreDescription>
                <TypographyWithHTMLParser variant="body2" text={messages.digitalStore.description} />
                <TypographyWithHTMLParser variant="body2" color="textSecondary" text={messages.digitalStore.linkTitle} />
                <TypographyWithHTMLParser variant="subtitle2" color="textPrimary" component="p" className="text-link" text={messages.digitalStore.link} />
              </DigitalStoreDescription>
            </StepContent>
            <StepContent>
              <StepTitle variant="h6" className="step_title">{messages.digitalStore.warningTitle}</StepTitle>
              <DigitalStoreDescription>
                <TypographyWithHTMLParser variant="body2" text={messages.digitalStore.warningDescription} />
              </DigitalStoreDescription>
            </StepContent>
          </DigitalStoreContainer>
        )}

      <StepContent>
        <StepTitle variant="h6" className="step_title">{messages.nextSteps.title}</StepTitle>
        <Divider>
          {steps.map((step, index) => (
            <StepContainer key={step}>
              <Number variant="h6" className="step_number">{index + 1}</Number>
              <TypographyWithHTMLParser color="textSecondary" variant="body2" className="step__description" text={messages.nextSteps[step]} />
            </StepContainer>
          ))}
        </Divider>
        {messages.nextSteps.footer && (
          <Divider>
            <TypographyWithHTMLParser variant="body2" className="step_footer" text={messages.nextSteps.footer} />
          </Divider>
         )}
      </StepContent>

      <StepTitle variant="h6" className="step_title">{messages.businessLeader.title}</StepTitle>
      <Divider>
        <TypographyWithHTMLParser variant="body2" text={messages.businessLeader.description} />
      </Divider>

      <Footer footerColor={messages.footer.color}>
        <ImageDynamicLoad alt={messages.iconAlts.consultant} />
        <FooterDescripton variant="subtitle2" className="bold">{messages.footer.description}</FooterDescripton>
        <Link href={messages.footer.playStoreHref} target="_blank">
          <AppBadgeWrapper>
            <PlayStoreIcon text={messages.footer.playStoreText} />
          </AppBadgeWrapper>
        </Link>
        {messages.footer.appleStoreHref && (
          <Link href={messages.footer.appleStoreHref} target="_blank">
            <AppBadgeWrapper>
              <AppStoreIcon text={messages.footer.appleStoreText} />
            </AppBadgeWrapper>
          </Link>
        )}
      </Footer>
    </ContentSuccess>
  </StatusCardContainer>
)
