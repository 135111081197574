import { usePageMessages } from '@/main/hooks/usePageMessages'/* eslint-disable jsx-a11y/anchor-is-valid */
import { MyProfileMessages } from '@/domain/models/messages/messages'
import React, { useContext, useEffect, useState } from 'react'
import {
  Container,
  GridContainer,
  GridItem,
  ProgressIndicator,
  Typography,
} from '@naturacosmeticos/natds-web'
import { IdentityInfoContext } from '@/main/contexts'
import { Page } from '@/domain/models/page/page'
import {
  PersonDataResponse,
} from '@/domain/use-cases/person/get-person-data'
import { CenteredLoadingContainer, LoadingContainer } from '@/main/components/pages-structures/container.styles'
import { useMyProfileStyles } from '@/main/pages/my-profile/my-profile.styles'
import { Icon } from '@/main/components'
import { DigitalSpace } from '@/main/pages/my-profile/digital-space/digital-space'
import { setObjectInStorage } from '@/main/hooks/useStorage'
import { validateStructureCodeSegmentation } from '@/domain/models/tenant/tenant-configs/helpers/validate-structure-code-segmentation'
import { Address } from './address/address'
import { ProfileContact } from './contact/contact'
import { ConsultantData } from './consultant-data/consultant-data'
import { MyProfileDataApi } from './api/my-profile-person-data-api'

export type MyProfileDataPageProps = {
  api: MyProfileDataApi
}

export const MyProfile: React.FC<MyProfileDataPageProps> = ({ api }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { countryId, companyId, personId, accessTokenNaturaWeb, loginId, externalComponent } = useContext(IdentityInfoContext)
  const messages = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const [person, setPerson] = useState<PersonDataResponse>(null)
  const [digitalStoreData, setDigitalStoreData] = useState<any>(null)
  const [address, setAddress] = useState<any>(null)
  const classComponent = useMyProfileStyles()
  const [showEditButton, setShowEditButton] = useState(false)

  useEffect(() => {
    const getValues = async () => {
      const person = await api.getMyProfile({ countryid: countryId, companyid: companyId, personId })
      const personData = person as PersonDataResponse
      if (!personData.person.naturaCode) {
        personData.person.naturaCode = loginId
      }
      setPerson(personData)
      setObjectInStorage('recommendedSalesHierarchies', personData.person.recommendedSalesHierarchies)
      setShowEditButton(validateStructureCodeSegmentation(personData.person.recommendedSalesHierarchies))
      const digitalStoreData = await api.getDigitalSpaceData({
        companyId, countryId, personCode: personData?.person.naturaCode, accessToken: accessTokenNaturaWeb
      })
      setDigitalStoreData(digitalStoreData)

      const addresses = await api.getMultipleAddresses({ personId, countryId, companyId })
      setAddress(addresses.deliveryAddress)

      setIsLoading(false)
    }
    getValues()
  }, [api])

  return isLoading
    ? (
      <LoadingContainer>
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
    : (
      <Container className={classComponent.container}>
        <GridContainer className="natds2" spacing={2}>
          <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
            <ConsultantData personData={person} />
          </GridItem>
          {
            externalComponent && (
              <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
                  {externalComponent}
              </GridItem>
            )
          }
          <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
            <div className={classComponent.sectionTitle}>
              <Icon name="outlined-social-person" size="small" className={classComponent.iconSectionTitle} />
              <Typography variant="h6">{messages.container.myData}</Typography>
            </div>
            <GridContainer className="natds1" spacing={2}>
              <GridItem className="natds2" lg={6} md={12} sm={12} xs={12}>
                <ProfileContact personData={person} />
              </GridItem>
              <GridItem className="natds2" lg={6} md={12} sm={12} xs={12}>
                <Address addressData={address} showEditButton={showEditButton} />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem className="natds2" lg={12} md={12} sm={12} xs={12}>
            <div className={classComponent.sectionTitle}>
              <Icon name="outlined-media-monitor" size="small" className={classComponent.iconSectionTitle} />
              <Typography variant="h6">{messages.container.digitalSpace}</Typography>
            </div>
            <DigitalSpace digitalStore={digitalStoreData} personData={person} />
          </GridItem>
        </GridContainer>
      </Container>
    )
}
