import { AddressPageType, AddressOrder } from '../../address'
import { AgreementProduct } from '../../agreement/agreement-product'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { Document } from '../../documents/documents'
import { PhoneType } from '../../person'
import { VendorId } from '../../vendor/vendor-id'
import {
  AddressConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.EMPTY,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.MyKAD,
      name: 'MyKAD',
      placeholder: 'MyKAD',
      validChars: /^[\d]{0,12}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 12,
        maxLength: 12,
      },
    },
    {
      type: Document.PassportMalaysia,
      name: 'Passport',
      placeholder: 'Passport',
      validChars: /^[\w]{0,15}$/m,
      rules: {
        pattern: /[\w]*/,
        minLength: 4,
        maxLength: 15,
      },
    },
  ],
}

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
  shouldDisplayEthnicity: true,
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: false,
  shouldCheckStatus: false,
  shouldOnlyShowConfirmation: true,
  shouldShowApprovedDescription: true,
  shouldShowContactInfos: true,
  goToClickButton: {
    show: false,
  },
  companyIconName: companyIconName[CompanyName.Natura]
}

const address: AddressConfigs = {
  pageType: AddressPageType.SimplePage,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 3 },
  street: {
    maxLength: 75,
  },
  complementaryData: {
    maxLength: 50,
  },
  city: {
    maxLength: 40,
  },
  state: {
    maxLength: 20,
  },

  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ]
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = null

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      length: 9,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 8,
    },
  ],
  shouldUseSeparatedNames: false,
  allocationStrategy: {
    zipCode: {
      rules: {
        maxLength: 5,
        pattern: new RegExp(/^\d{0,5}$/),
      },
      shouldDisplayLink: () => false,
    },
  },
}

const profile: ProfileConfigs = null

export const naturaMysConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments: null,
  preRegister,
  address,
  digitalSignaturePage: null,
  digitalStore,
  completed,
  authorizationCode: null,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
