import { CompanyId } from '@/domain/models/company/company-id'
import { consultoriaDeBeleza_light_full_theme } from '@/mock/ConsultoriaDeBeleza_mock/theme_consultoriaDeBeleza_mock_light'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { themes } from '@naturacosmeticos/natds-web'
import get from 'lodash/get'
import { useEffect, useState } from 'react'

const allThemes = {
  [CompanyId.Natura]: themes.natura,
  [CompanyId.Avon]: themes.avon,
}
const themeMap = (themeWeb, themeReact) => {

  themeWeb.color = { ...themeWeb.color, ...themeReact.color }
  themeWeb.palette.primary.main = themeReact.color.primary
  themeWeb.palette.primary.dark = themeReact.color.primaryDark
  themeWeb.palette.secondary.main = themeReact.color.secondary

  themeWeb.overrides.MuiExpansionPanel.root['&$expanded'].borderColor = themeReact.color.primary

  themeWeb.overrides.MuiInputBase.root['&.Mui-focused'].boxShadow = `${themeReact.color.primary} 0 0 0 2px}`
  
  return themeWeb
}

export const useTheme = (companyId = CompanyId.Natura) => {
  const [theme, setTheme] = useState(themes.natura)


  useEffect(() => {

    if (getEloToggleByCountry()){
      const newThemeMock = themeMap(theme.light, consultoriaDeBeleza_light_full_theme)
      const newTheme = get(allThemes, companyId, themes.natura)
      newTheme.light = newThemeMock
      setTheme(newTheme)

    } else {

      const newTheme = get(allThemes, companyId, themes.natura)
      setTheme(newTheme)
    }



  }, [companyId])

  return theme
}
