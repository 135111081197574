import { AttachmentType } from '@/domain/models/attachments/attachments'
import { AddressPageType, AddressOrder } from '../../address'
import { AgreementProduct } from '../../agreement/agreement-product'
import { companyIconName } from '../../company/company-icon-name'
import { CompanyName } from '../../company/company-name'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { Document } from '../../documents/documents'
import { PhoneType } from '../../person'
import { VendorId } from '../../vendor/vendor-id'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: false,
  agreementProducts: [AgreementProduct.NATURA],
  shouldHaveBirthState: false,
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.DniArgentina,
      name: 'DNI',
      attachments: [
        { title: 'Frente', type: AttachmentType.DniArgentinaFront },
        { title: 'Dorso', type: AttachmentType.DniArgentinaBack },
      ],
    },
  ],
  canChooseDocument: false,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType:
    process.env.ARGENTINA_ADDRESS_PAGE_STATELESS_TOGGLE === 'true'
      ? AddressPageType.GeographicalLevels
      : AddressPageType.Legacy,
  shouldDisableTheFilledInput: false,
  zipCode: {
    rules: {
      maxLength: 4,
      pattern: new RegExp(/^\d{0,4}$/),
    },
    shouldDisplayLink: ({ authenticated }) => !authenticated,
  },
  geographicalLevels: { length: 4 },
  number: {
    validChars: new RegExp(/^\d+$/),
    hasNoNumberCheckbox: true,
  },
  showDeliveryCheckbox: true,
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: true,
  },
  shouldSearchIndication: true,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  goToClickButton: {
    show: false,
  },
  companyIconName: companyIconName[CompanyName.Natura]
}

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: undefined,
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.DniArgentina,
      validChars: /^[\d]{0,8}$/m,
      name: 'dni',
      rules: {
        pattern: /[\d]*/,
        minLength: 7,
        maxLength: 8,
      },
    },
  ],
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = null

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 10,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 10,
    },
  ],
  shouldUseSeparatedNames: process.env.SHOULD_USE_SEPARATED_NAMES === 'true',
  namesOptions: ['firstName', 'lastName'],
  allocationStrategy: { levels: { length: 4 } },
}

const profile: ProfileConfigs = null

export const naturaArgConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  digitalStore,
  address,
  digitalSignaturePage: null,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
