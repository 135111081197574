import { TFunction } from 'i18next'
import { AddressMessages } from '@/main/pages/address/models'

export function getAddressMessages(translate: TFunction, options): AddressMessages {
  return {
    title: translate('address.title'),
    deliveryTitle: translate('address.deliveryTitle'),
    subtitle: translate('address.subtitle', options),
    mainStreet: translate('address.mainStreet'),
    number: translate('address.number'),
    complementaryData: translate('address.complementaryData'),
    cologne: translate('address.cologne'),
    neighborhood: translate('address.neighborhood'),
    state: translate('address.state'),
    closeButtonText: translate('unexpectedErrorDialog.button'),
    unexpectedError: translate('unexpectedErrorDialog.message'),
    checkBoxWithoutNumber: translate('address.checkBoxWithoutNumber'),
    references: translate('address.references'),
    referencesHelperText: translate('referencesHelperText'),
    city: translate('address.city'),
    confirmDeliveryAddressAsResidenceAddress: translate('address.confirmDeliveryAddressAsResidenceAddress'),
    previousButtonLabel: translate('previousButtonLabel'),
    nextButtonLabel: translate('nextButtonLabel'),
    alternativeFlowButtonLabel: translate('addressPage.alternativeFlowButtonLabel'),
    skipButtonLabel: translate('addressPage.skipButtonLabel'),
    alternativeFlowDialogSucess: {
      infoText: translate('addressPage.alternativeFlowDialogSucess.infoText'),
      closeButtonText: translate('addressPage.alternativeFlowDialogSucess.closeButtonText'),
    },
    alternativeFlowDialogFail: {
      infoText: translate('addressPage.alternativeFlowDialogFail.infoText'),
      closeButtonText: translate('addressPage.alternativeFlowDialogFail.closeButtonText'),
    },
    addressRequiredError: translate('requiredErrorMessage'),
    optionalTextLabel: translate('optionalTextLabel'),
    zipCode: {
      notFoundZipCode: translate('address.zipCode.notFoundZipCode'),
      label: translate('address.zipCode.label'),
      errorMessage: translate('address.zipCode.digitsError'),
      placeholder: translate('address.placeholders.zipCode'),
      link: {
        text: translate('address.zipCode.link.text'),
        url: translate('address.zipCode.link.url'),
      },
      requiredErrorMessage:
        translate('address.zipCode.empty') !== '__STRING_NOT_TRANSLATED__'
          ? translate('address.zipCode.empty')
          : translate('requiredErrorMessage'),
    },
    rules: {
      maxLength: translate('address.rules.maxLength'),
    },
    delivery: translate('delivery'),
    placeholders: {
      number: translate('address.placeholders.number'),
      mainStreet: translate('address.placeholders.mainStreet'),
      complementaryData: translate('address.placeholders.complementaryData'),
      department: translate('address.placeholders.department'),
      city: translate('address.placeholders.city'),
      neighborhood: translate('address.placeholders.neighborhood'),
      references: translate('address.placeholders.references'),
      cologne: translate('address.placeholders.cologne'),
      state: translate('address.placeholders.state'),
    },
    geographicalLevels: {
      level1: translate('geographicalLevels.level1'),
      level2: translate('geographicalLevels.level2'),
      level3: translate('geographicalLevels.level3'),
      level4: translate('geographicalLevels.level4'),
      placeholders: {
        level1: translate('geographicalLevels.placeholders.level1'),
        level2: translate('geographicalLevels.placeholders.level2'),
        level3: translate('geographicalLevels.placeholders.level3'),
        level4: translate('geographicalLevels.placeholders.level4'),
      },
    },
    addressDeliveryType: {
      delivery: translate('maintenanceAddressPage.delivery'),
      mailing: translate('maintenanceAddressPage.mailing'),
      billing: translate('maintenanceAddressPage.billing'),
    },
  }
}
