import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpPatchClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { AddressType } from '@/domain/models/address/address-storge'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { BUSINESS_MODELS, ROLES, FUNCTION_IDS } from '@/domain/models/person'
import { AddressPageForms } from './remote-get-multiple-address'

type SaveAddressHeaders = {
    countryId: CountryId
    companyId: CompanyId
    'x-api-key': string
    function: FUNCTION_IDS,
    role: ROLES,
    businessModel: BUSINESS_MODELS,
    sourceSystem: string,
}

export type AddressSavePageForms = {
  receiveBillingAtSameAddress: boolean
} & AddressPageForms

export type SaveAddressParams = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  address: AddressType
  functionId: FUNCTION_IDS,
  role: ROLES,
  businessModel: BUSINESS_MODELS,
  sourceSystem: string,
}

export class RemoteSaveAddress {
    constructor(
        private readonly httpPatchClient: HttpPatchClient<SaveAddressHeaders, AddressType>,
        private readonly xApiKey: string,
    ) {}

    update = async (params : SaveAddressParams): Promise<void> => {
        const httpResponse = await this.httpPatchClient.patch({
          url: `/address/people/update-address/${params.personId}`,
          headers: {
           'x-api-key': this.xApiKey,
           'function': params.functionId,
           role: params.role,
           'businessModel': params.businessModel,
           'sourceSystem': params.sourceSystem,
           'countryId': params.countryId,
           'companyId': params.companyId,
         },
          body: params.address,
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
            case HttpStatusCode.noContent:
            case HttpStatusCode.created:
                return
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
