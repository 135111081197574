import {
  MaintenanceSecurityValidationMessages,
  MaintenanceSecurityValidationOptions,
} from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceSecurityValidation(
  translate: TFunction,
  { email, sms }: MaintenanceSecurityValidationOptions
): MaintenanceSecurityValidationMessages {
  return {
    labels: {
      email: translate('maintenanceSecurityValidation.labels.email', { email }),
      sms: translate('maintenanceSecurityValidation.labels.sms', { sms }),
      previousButton: translate('maintenanceSecurityValidation.labels.previousButton'),
      saveButton: translate('maintenanceSecurityValidation.labels.saveButton'),
    },
    title: translate('maintenanceSecurityValidation.title'),
    subtitle: translate('maintenanceSecurityValidation.subtitle'),
    question: translate('maintenanceSecurityValidation.question'),
    closeButtonText: translate('unexpectedErrorDialog.button'),
    unexpectedError: translate('unexpectedErrorDialog.message'),
  }
}
