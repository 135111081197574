import { PersonalDataMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getPersonalDataMessages(translate: TFunction): PersonalDataMessages {
  return {
    title: translate('personalDataPage.title'),
    subtitle: translate('personalDataPage.subtitle'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
    fullName: {
      label: translate('personalDataPage.fullName.label'),
      placeholder: translate('personalDataPage.fullName.placeholder'),
      errorMessage: translate('personalDataPage.fullName.errorMessage'),
      infoText: translate('personalDataPage.fullName.infoText'),
    },
    separatedNames: {
      firstName: {
        label: translate('personalDataPage.separatedNames.firstName.label'),
        placeholder: translate('personalDataPage.separatedNames.firstName.placeholder'),
      },
      middleName: {
        label: translate('personalDataPage.separatedNames.middleName.label'),
        placeholder: translate('personalDataPage.separatedNames.middleName.placeholder'),
      },
      lastName: {
        label: translate('personalDataPage.separatedNames.lastName.label'),
        placeholder: translate('personalDataPage.separatedNames.lastName.placeholder'),
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    phone: {
      label: translate('personalDataPage.phoneType.label'),
      options: {
        cellular: {
          label: translate('personalDataPage.phoneType.cellular.label'),
          phoneNumber: {
            placeholder: translate('personalDataPage.phoneType.cellular.phoneNumber.placeholder'),
            errorMessage: {
              firstDigit: translate('personalDataPage.phoneType.cellular.phoneNumber.firstDigit'),
              length: translate('personalDataPage.phoneType.cellular.phoneNumber.length'),
            },
          },
        },
        line: {
          label: translate('personalDataPage.phoneType.line.label'),
          phoneNumber: {
            placeholder: translate('personalDataPage.phoneType.line.phoneNumber.placeholder'),
            errorMessage: {
              firstDigit: translate('personalDataPage.phoneType.line.phoneNumber.firstDigit'),
              length: translate('personalDataPage.phoneType.line.phoneNumber.length'),
            },
          },
        },
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    email: {
      label: translate('personalDataPage.email.label'),
      placeholder: translate('personalDataPage.email.placeholder'),
      errorMessage: translate('personalDataPage.email.errorMessage'),
      infoText: translate('personalDataPage.email.infoText'),
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    geographicalLevels: {
      level1: translate('geographicalLevels.level1'),
      level2: translate('geographicalLevels.level2'),
      level3: translate('geographicalLevels.level3'),
      level4: translate('geographicalLevels.level4'),
      placeholders: {
        level1: translate('geographicalLevels.placeholders.level1'),
        level2: translate('geographicalLevels.placeholders.level2'),
        level3: translate('geographicalLevels.placeholders.level3'),
        level4: translate('geographicalLevels.placeholders.level4'),
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    zipCode: {
      label: translate('personalDataPage.zipCode.label'),
      placeholder: translate('personalDataPage.zipCode.placeholder'),
      errorMessage: translate('personalDataPage.zipCode.errorMessage'),
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    searchAddress: {
      label: translate('personalDataPage.searchAddress.label'),
      placeholder: translate('personalDataPage.searchAddress.placeholder'),
      notFound: translate('personalDataPage.searchAddress.notFound'),
      loading: translate('personalDataPage.searchAddress.loading'),
      error: {
        predictions: translate('personalDataPage.searchAddress.error.predictions'),
        address: translate('personalDataPage.searchAddress.error.address'),
      },
      rules: {
        required: translate('personalDataPage.searchAddress.rules.required'),
      },
    },
    recommender: {
      noRecommenderText: {
        label: translate('personalDataPage.recommender.noRecommenderText.label'),
      },
      selectedPersonLabel: translate('personalDataPage.recommender.selectedPersonLabel'),
      input: {
        label: translate('personalDataPage.recommender.input.label'),
        placeholder: translate('personalDataPage.recommender.input.placeholder'),
      },
      button: {
        text: translate('personalDataPage.recommender.button.text'),
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
      modal: {
        labelCode: translate('personalDataPage.recommender.modal.labelCode'),
        searchNotFound: translate('personalDataPage.recommender.modal.searchNotFound'),
        searchError: translate('personalDataPage.recommender.modal.searchError'),
        okButtonLabel: translate('personalDataPage.recommender.modal.okButtonLabel'),
        cancelButtonLabel: translate('personalDataPage.recommender.modal.cancelButtonLabel'),
        confirmButtonLabel: translate('personalDataPage.recommender.modal.confirmButtonLabel'),
        alertTitle: translate('personalDataPage.recommender.modal.alertTitle'),
        instructionsTitle: translate('personalDataPage.recommender.modal.instructionsTitle'),
      },
    },
    dialog: {
      infoText: {
        unexpectedError: translate('personalDataPage.dialog.unexpectedError'),
        emailDuplicated: translate('personalDataPage.dialog.emailDuplicated'),
        invalidAddress: translate('personalDataPage.dialog.invalidAddress'),
      },
      closeButtonText: translate('personalDataPage.dialog.closeButtonText'),
    },
    allocation: {
      searchAddressWithZipCode: {
        cologne: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.cologne.label'),
          placeholder: translate('personalDataPage.allocation.searchAddressWithZipCode.cologne.placeholder'),
          rules: {
            required: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.cologne.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.cologne.rules.maxLength'
            ),
          },
        },
        complement: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.complement.label'),
          placeholder: translate(
            'personalDataPage.allocation.searchAddressWithZipCode.complement.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.complement.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.complement.rules.maxLength'
            ),
          },
        },
        city: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.city.label'),
          placeholder: translate('personalDataPage.allocation.searchAddressWithZipCode.city.placeholder'),
          rules: {
            required: translate('personalDataPage.allocation.searchAddressWithZipCode.city.rules.required'),
            maxLength: translate('personalDataPage.allocation.searchAddressWithZipCode.city.rules.maxLength'),
          },
        },
        neighborhood: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.neighborhood.label'),
          placeholder: translate(
            'personalDataPage.allocation.searchAddressWithZipCode.neighborhood.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.neighborhood.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.neighborhood.rules.maxLength'
            ),
          },
        },
        number: {
          checkboxWithoutNumber: translate(
            'personalDataPage.allocation.searchAddressWithZipCode.number.checkboxWithoutNumber'
          ),
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.number.label'),
          placeholder: translate('personalDataPage.allocation.searchAddressWithZipCode.number.placeholder'),
          rules: {
            required: translate('personalDataPage.allocation.searchAddressWithZipCode.number.rules.required'),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.number.rules.maxLength'
            ),
          },
        },
        reference: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.reference.label'),
          placeholder: translate(
            'personalDataPage.allocation.searchAddressWithZipCode.reference.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.reference.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.reference.rules.maxLength'
            ),
          },
        },
        state: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.state.label'),
          placeholder: translate('personalDataPage.allocation.searchAddressWithZipCode.state.placeholder'),
          rules: {
            required: translate('personalDataPage.allocation.searchAddressWithZipCode.state.rules.required'),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.state.rules.maxLength'
            ),
          },
        },
        street: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.street.label'),
          placeholder: translate('personalDataPage.allocation.searchAddressWithZipCode.street.placeholder'),
          rules: {
            required: translate('personalDataPage.allocation.searchAddressWithZipCode.street.rules.required'),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.street.rules.maxLength'
            ),
          },
        },
        zipCode: {
          label: translate('personalDataPage.allocation.searchAddressWithZipCode.zipCode.label'),
          placeholder: translate('personalDataPage.allocation.searchAddressWithZipCode.zipCode.placeholder'),
          rules: {
            required: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.zipCode.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.zipCode.rules.maxLength'
            ),
            minLength: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.zipCode.rules.minLength'
            ),
            pattern: translate('personalDataPage.allocation.searchAddressWithZipCode.zipCode.rules.pattern'),
          },
        },
        dialog: {
          closeButton: translate('personalDataPage.allocation.searchAddressWithZipCode.dialog.closeButton'),
          error: {
            notFound: translate('personalDataPage.allocation.searchAddressWithZipCode.dialog.error.notFound'),
            unexpected: translate(
              'personalDataPage.allocation.searchAddressWithZipCode.dialog.error.unexpected'
            ),
          },
        },
      },
      deliveryAddressWithZipCode: {
        deliveryCologne: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCologne.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCologne.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCologne.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCologne.rules.maxLength'
            ),
          },
        },
        deliveryComplement: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryComplement.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryComplement.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryComplement.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryComplement.rules.maxLength'
            ),
          },
        },
        deliveryCity: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCity.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCity.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCity.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryCity.rules.maxLength'
            ),
          },
        },
        deliveryNeighborhood: {
          label: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNeighborhood.label'
          ),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNeighborhood.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNeighborhood.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNeighborhood.rules.maxLength'
            ),
          },
        },
        deliveryNumber: {
          checkboxWithoutNumber: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNumber.checkboxWithoutNumber'
          ),
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNumber.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNumber.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNumber.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryNumber.rules.maxLength'
            ),
          },
        },
        deliveryReference: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryReference.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryReference.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryReference.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryReference.rules.maxLength'
            ),
          },
        },
        deliveryState: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryState.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryState.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryState.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryState.rules.maxLength'
            ),
          },
        },
        deliveryStreet: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryStreet.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryStreet.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryStreet.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryStreet.rules.maxLength'
            ),
          },
        },
        deliveryZipCode: {
          label: translate('personalDataPage.allocation.deliveryAddressWithZipCode.deliveryZipCode.label'),
          placeholder: translate(
            'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryZipCode.placeholder'
          ),
          rules: {
            required: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryZipCode.rules.required'
            ),
            maxLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryZipCode.rules.maxLength'
            ),
            minLength: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryZipCode.rules.minLength'
            ),
            pattern: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.deliveryZipCode.rules.pattern'
            ),
          },
        },
        dialog: {
          closeButton: translate('personalDataPage.allocation.deliveryAddressWithZipCode.dialog.closeButton'),
          error: {
            notFound: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.dialog.error.notFound'
            ),
            unexpected: translate(
              'personalDataPage.allocation.deliveryAddressWithZipCode.dialog.error.unexpected'
            ),
          },
        },
      },
    },
  }
}
