import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { BUSINESS_MODELS, FUNCTION_IDS, PhoneType, ROLES } from '@/domain/models/person'
import { USER_PROFILE_TYPE } from '@/domain/models/person/user-profile-type'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import React, { createContext, ReactNode } from 'react'

interface IdentityInfoProviderProps {
  info: IdentityInfo
  children: ReactNode
}

type commercialStructure = { 
  groupId:string, 
  sectorId: string 
  gvId:string 
  reId:string 
  gmId: string 
}

export type IdentityInfo = {
  personId: string
  commercialStructure?: commercialStructure
  countryId: CountryId
  tenantId: TenantId
  shouldCreatePerson?: boolean
  businessModel?: BUSINESS_MODELS
  role?: ROLES
  functionId?: FUNCTION_IDS
  sourceSystem?: string
  companyId?: CompanyId
  authorization: string
  userId?: string
  userName?: string
  phoneNumber?: string
  phoneType?: PhoneType
  email?: string
  shouldCallCompleteEndpoint?: boolean
  isPossibleToBypass?: boolean
  consultantId?: string
  goBackPage?: () => {}
  personalDataSearchAddressWithZipCodeToggle?: boolean
  disableFieldsPersonalData?: boolean
  authorizationCodeMessageAvonMX?: boolean
  accessTokenNaturaWeb?: string
  loginId?: string
  externalComponent?: React.Component
}

type Profile = {
  isCN: boolean
}

export type IdentityInfoContextData = {
  profile: Profile
} & IdentityInfo

export const IdentityInfoContext = createContext({} as IdentityInfoContextData)

export const IdentityInfoProvider = ({ info, children }: IdentityInfoProviderProps) => {
  const { businessModel, functionId, role } = info

  const isCN = getUserProfileType({ businessModel, functionId, role }) === USER_PROFILE_TYPE.CN

  const profile: Profile = {
    isCN,
  }

  return <IdentityInfoContext.Provider value={{ ...info, profile }}>{children}</IdentityInfoContext.Provider>
}

type GetRoleParams = {
  businessModel: BUSINESS_MODELS
  functionId: FUNCTION_IDS
  role: ROLES
}

function getUserProfileType({ businessModel, functionId, role }: GetRoleParams): USER_PROFILE_TYPE {
  const profile = {
    [`${BUSINESS_MODELS.DIRECT_SALE}${FUNCTION_IDS.BEAUTY_CONSULTANT}${ROLES.CONSULTANT}`]: USER_PROFILE_TYPE.CN,
  }

  return profile[`${businessModel}${functionId}${role}`]
}
