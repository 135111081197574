import { getObjectInStorage } from '@/main/hooks/useStorage'

export const getEloToggleByCountry = () => {
    const availableCountries = ['PE']

    const currentLocale = getObjectInStorage('currentLocale') as any
    
    const isActivatedToCountry = availableCountries.includes(currentLocale?.region)

    return currentLocale?.companyId === 1 && isActivatedToCountry
}