import { Loading } from '@/main/pages/confirmation/loading'
import React from 'react'
import { useI18n } from '@/main/hooks/useI18n'

type Slide = {
  title: string
  description: string
  image?: string
}

type CarouselMessages = {
  slide1: Slide
  slide2?: Slide
  slide3?: Slide
  slide4?: Slide
  slide5?: Slide
  slide6?: Slide
}

export type LoadingMessages = {
  description: {
    first: string
    second: string
  }
  durationText: string
  carousel: CarouselMessages
}

type LoadingWrapperProps = {
  duration: number
}

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ duration }) => {
  const { translate } = useI18n()

  const defaultImages = [
    'natural_formulas',
    'no_animal_testing',
    'safe_for_you_and_environment',
    'commitment_to_the_climate',
    'eco_friendly_packaging',
    'beware_of_the_origin',
  ]
  const slides = {} as CarouselMessages
  defaultImages.forEach((image, i) => {
    const count = i + 1
    slides[`slide${count}`] = {
      title: translate(`completedPage.loading.carousel.slide${count}.title`, ''),
      description: translate(`completedPage.loading.carousel.slide${count}.description`, ''),
      image: translate(`completedPage.loading.carousel.slide${count}.image`, image),
    }
  })

  const messages: LoadingMessages = {
    description: {
      first: translate('completedPage.loading.description.first'),
      second: translate('completedPage.loading.description.second'),
    },
    durationText: translate('completedPage.loading.durationText', { duration }),
    carousel: slides,
  }

  return <Loading messages={messages} />
}
