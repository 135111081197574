import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'

type SendCodeValidationHeaders = {
    countrycode: string
    companyid: CompanyId
    'x-api-key': string
}

type SendCodeValidationBody = {
    channel: string
}

export type SendCodeValidationParams = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  channel: string
}

export class RemoteSendCodeValidation {
    constructor(
        private readonly httpPostClient: HttpPostClient<SendCodeValidationHeaders, SendCodeValidationBody>,
        private readonly xApiKey: string,
    ) {}
    
    async send(params : SendCodeValidationParams): Promise<void> {
        const { channel, countryId, companyId, personId } = params
        const httpResponse = await this.httpPostClient.post({
          url: `/validation-code/create/change_address/${personId}`,
          headers: {
           'x-api-key': this.xApiKey,
            companyid: companyId,
            countrycode: countryId,
         },
         body: {
            channel
         }
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
            case HttpStatusCode.created:
            case HttpStatusCode.noContent:
                return 
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
