import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { DocumentType } from '@/domain/models/documents/document'
import { AddressesType, EmailsType, PhonesType } from '@/main/contexts'

export type GetPersonRelationsOptions =
  'additionalInformation' |
  'addresses' |
  'approvedCandidates' |
  'attachments' |
  'call' |
  'contactReferences' |
  'documents' |
  'emails' |
  'paymentPlan' |
  'personPoliciesAccepts' |
  'personRoles' |
  'personRolesFunctions' |
  'products' |
  'recommendedSalesHierachies' |
  'socialMedias' |
  'telephones' |
  'verifications'

export type GetPersonDataParams = {
  personId: string
  countryId: CountryId
  companyId: CompanyId
  relations: GetPersonRelationsOptions[],
}

export enum HIERARCHIES_LEVEL_ID {
  COUNTRY = 0,
  GM = 1,
  RE = 2,
  GV = 3,
  SECTOR = 4,
  GROUP = 5,
}

export type Hierarchies = {
   cycle: number,
   id : string,
   levelId: number
}

export type PersonData = {
  birthday?: string
  recommendedSalesHierarchies?: Hierarchies[]
  countryId?: number
  createdAt?: string
  createdBy?: string
  createdFrom?: number
  fatherName?: string
  firstName?: string
  gender?: number
  lastName?: string
  motherName?: null
  name?: string
  nationalityId?: number
  nickname?: string
  occupationId?: number
  personId?: string
  registrationDate?: string
  registrationStatusId?: number
  registrationSubstatusId?: number
  sourceSystem?: number
  tenantId?: string
  updatedAt?: string
  updatedBy?: string
  emails?: EmailsType[]
  phones?: PhonesType[]
  addresses?: AddressesType[]
  documents?: DocumentType[]
  additionalInformation?: any
  naturaCode?: string
}

export type PersonDataResponse = {
  person: PersonData
}

export interface GetPersonData {
  get: (data: GetPersonDataParams) => Promise<PersonData | PersonDataResponse | {} | null | unknown >
}
