import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Container } from '@/main/components/pages-structures'
import { Typography } from '@naturacosmeticos/natds-web'
import { useForm } from 'react-hook-form'
import { Page } from '@/domain/models/page/page'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { MaintenanceSecurityValidationMessages, MaintenanceSecurityValidationOptions } from '@/domain/models/messages/messages'
import { ControlledFVRadioButton } from '@/main/components/radio-button'
import { IdentityInfoContext } from '@/main/contexts'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { setObjectInStorage } from '@/main/hooks/useStorage'
import { CHANNEL_STORAGE_NAME } from '@/domain/models/address'
import { useMaintenanceSecurityValidation } from './maintenance-security-validation.styles'
import { SecurityValidationPageApi } from './api/make-maintenance-security-validation-page-api'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'

export type SecurityValidationPageProps = {
  api: SecurityValidationPageApi
}

export const MaintenanceSecurityValidation: React.FC<SecurityValidationPageProps> = ({ api }) => {

  const messages = usePageMessages(Page.MaintenanceSecurityValidation, {
    email: '',
    sms: '',
  }).messages as MaintenanceSecurityValidationMessages

  const { personId, countryId, companyId} = useContext(IdentityInfoContext)

  const [isLoading, setIsLoading] = useState(true)
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [radioButton, SetRadioButton] = useState('')

 
  const [cnContacts, setCNContacts] = useState<MaintenanceSecurityValidationOptions>({
    email: {
      sequence: '',
      value: ''
    },
    sms: {
      sequence: '',
      value: ''
    }
  } as MaintenanceSecurityValidationOptions)

  const getContacts = useCallback(async () => {
    try {
      const response = await api.getCNContacts({personId, countryId, companyId})
      setCNContacts(response)
    } catch {
      setIsErrorDialogOpen(true)
      console.warn('Error on get consultant contacts')
    } finally {
      setIsLoading(false)
    }
  }, [api, companyId, countryId, personId])

  useEffect(() => {
    getContacts()   
  }, [getContacts])

  const classes = useMaintenanceSecurityValidation()

  const radioButtonName = 'securityRadioButton'
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      [radioButtonName]: '',
    },
  })
  const { control } = formMethods

  const goBackPreviousPage = () => window.location.assign(`/webfv/mfe-register/maintenanceAddressPage/${personId}`)

  const handleSubmit = async () => {
    setOpenSubmitLoading(true)
    try {
      const channel = radioButton
      const { sequence } = cnContacts[channel.toLowerCase()]
      
      await api.sendCodeValidation({
        countryId,
        companyId,
        personId,
        channel
      })
      setObjectInStorage(CHANNEL_STORAGE_NAME, { channel, sequence })
      window.location.assign(`/webfv/mfe-register/maintenanceValidationCode/${personId}`)
    } catch (error) {
      console.error(error)
      setIsErrorDialogOpen(true)
      setOpenSubmitLoading(false)
    }
  }

  return (
    <Container
      nextButtonLabel={messages.labels.saveButton}
      onNextButtonClick={handleSubmit}
      disableNextButton={Boolean(!radioButton)}
      previousButtonLabel={messages.labels.previousButton}
      onPreviousButtonClick={goBackPreviousPage}
      isLoading={isLoading}
    >
      <Typography variant="h6">{messages.title}</Typography>

      <div className={classes.subtitle}>
        <Typography>{messages.subtitle}</Typography>
      </div>

      <Typography>{messages.question}</Typography>

      <ControlledFVRadioButton
        id="security-validation-fv-radio-button-email-id"
        testID="security-validation-fv-radio-button-email-test-id"
        name={radioButtonName}
        label={`${messages.labels.email}${cnContacts.email.value.toLocaleLowerCase()}`}
        value="EMAIL"
        onChange={()=>SetRadioButton('EMAIL')}
        control={control}
        checked={radioButton === 'EMAIL'}
        className={classes.emailRadioButton}
        required
      />

      <ControlledFVRadioButton
        id="security-validation-fv-radio-button-sms-id"
        testID="security-validation-fv-radio-button-sms-test-id"
        name={radioButtonName}
        label={`${messages.labels.sms}${cnContacts.sms.value}`}
        value="SMS"
        onChange={()=>SetRadioButton('SMS')}
        control={control}
        checked={radioButton === 'SMS'}
        required
      />
      <DialogAlert
        show={isErrorDialogOpen}
        messages={{ infoText: messages.unexpectedError, closeButtonText: messages.closeButtonText }}
        closeDialogCallback={() => setIsErrorDialogOpen(false)}
      />
      <DialogWithLoader isOpen={openSubmitLoading} />
    </Container>
  )
}
