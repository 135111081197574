import { LevelName } from '@/domain/models/address'
import { CountryId } from '@/domain/models/country'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import {
  CardContent,
  CardHeader,
  CardTitle,
  ControlledAddressNumberField,
  ControlledTextField,
  GeoCommercialStructure,
  ZipCodeTextField,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { getGeoCommercialConfig } from '@/main/components/geo-commercial-fields/build-geo-commercial-structure-config'
import { Container } from '@/main/components/pages-structures'
import {
  AuthenticatedUserContext,
  IdentityInfoContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useAddressPageStyles } from '../address-page.styles'
import { AddressPageApi } from '../api'
import { useAddressData } from '../hooks/use-address-data'
import { AddressFormValues, AddressMessages, GeographicalLevelsFormsValues } from '../models'

type GeographicalLevelsAddressPageProps = {
  api: Pick<AddressPageApi, 'complete' | 'getAddressData'>
}

export const GeographicalLevelsAddressPage: React.FC<GeographicalLevelsAddressPageProps> = ({ api }) => {
  const { tenantId, businessModel, role, functionId, personId } = useContext(IdentityInfoContext)
  const { goToPreviousPage, goToNextPage } = useContext<NavigationContextProps>(NavigationContext)

  const formMethods = useForm<AddressFormValues & GeographicalLevelsFormsValues>({ mode: 'onTouched' })
  const {
    control,
    formState: { isValid, isSubmitting },
    reset,
    getValues,
  } = formMethods
  const [copyAddress, setCopyAddress] = useState<boolean>(true)
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false)
  const messages = usePageMessages(Page.Address).messages as AddressMessages
  const { zipCode, geographicalLevels, number, reference, showDeliveryCheckbox } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs
  const { authenticated } = useContext(AuthenticatedUserContext)

  const { isLoading: isFetchingAddressData, data } = useAddressData({ getAddressData: api.getAddressData })

  useEffect(() => {
    const hasSavedData = Object.values(data).some((formField) => formField)
    if (hasSavedData) {
      const hasSavedDeliveryData = Object.entries(data).some(
        ([key, value]) => key.includes('delivery') && value
      )
      if (hasSavedDeliveryData) {
        setCopyAddress(false)
      }
      reset(data)
    }
  }, [data, reset])

  const formRules = { required: messages.addressRequiredError }

  const addressPageClasses = useAddressPageStyles()

  const onNextButtonClick = () => {
    const formValues = getValues()
    api
      .complete({
        data: { ...formValues, copyAddress },
        tenantId,
        businessModel,
        role,
        functionId,
        personId,
      })
      .then(() => goToNextPage())
      .catch(() => setIsErrorDialogOpen(true))
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onNextButtonClick}
      disableNextButton={!isValid}
      isLoading={isSubmitting || isFetchingAddressData}
    >
      <CardHeader title={messages.title} subtitle={messages.subtitle} />
      <CardContent>
        <FormProvider {...formMethods}>
          {zipCode && (
            <ZipCodeTextField
              id={LevelName.ZipCode}
              label={messages.zipCode.label}
              placeholder={messages.zipCode.placeholder}
              link={{
                show: zipCode.shouldDisplayLink({ authenticated }),
                text: messages.zipCode.link.text,
                url: messages.zipCode.link.url,
              }}
              rules={{
                minLength: {
                  message: messages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                maxLength: {
                  message: messages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                required: {
                  value: true,
                  message: messages.zipCode.requiredErrorMessage,
                },
              }}
              validChars={zipCode.rules.pattern}
              control={control}
            />
          )}
          <ControlledTextField
            id={LevelName.Street}
            label={messages.mainStreet}
            control={control}
            rules={formRules}
            placeholder={messages.placeholders.mainStreet}
            showAsterisk={false}
            required
          />
          <ControlledAddressNumberField
            id={LevelName.Number}
            label={messages.number}
            placeholder={messages.placeholders.number}
            rules={{
              required: {
                message: messages.addressRequiredError,
                value: true,
              },
            }}
            noNumberLabel={messages.checkBoxWithoutNumber}
            showAsterisk={false}
            validChars={number.validChars}
            hasNoNumberCheckbox={number?.hasNoNumberCheckbox}
          />
          <ControlledTextField
            id={LevelName.Complement}
            label={messages.complementaryData}
            control={control}
            placeholder={messages.placeholders.complementaryData}
            optionalText={messages.optionalTextLabel}
          />
          {reference && (
            <ControlledTextField
              id={LevelName.References}
              label={messages.references}
              control={control}
              rules={formRules}
              placeholder={messages.placeholders.references}
            />
          )}

          {showDeliveryCheckbox && (
            <div className={addressPageClasses.copyAddressCheckbox}>
              <Checkbox
                color="secondary"
                label={messages.confirmDeliveryAddressAsResidenceAddress}
                checked={copyAddress}
                onChange={(e) => setCopyAddress(e.target.checked)}
              />
            </div>
          )}
          {!copyAddress && (
            <>
              <Spacing marginY="semi">
                <Divider />
              </Spacing>
              <CardTitle title={messages.deliveryTitle} />
              {zipCode && (
                <ZipCodeTextField
                  id={LevelName.DeliveryZipCode}
                  label={messages.zipCode.label}
                  placeholder={messages.zipCode.placeholder}
                  link={{
                    show: zipCode.shouldDisplayLink({ authenticated }),
                    text: messages.zipCode.link.text,
                    url: messages.zipCode.link.url,
                  }}
                  rules={{
                    minLength: {
                      message: messages.zipCode.errorMessage,
                      value: zipCode.rules.maxLength,
                    },
                    maxLength: {
                      message: messages.zipCode.errorMessage,
                      value: zipCode.rules.maxLength,
                    },
                    required: {
                      value: true,
                      message: messages.zipCode.requiredErrorMessage,
                    },
                  }}
                  validChars={zipCode.rules.pattern}
                  control={control}
                />
              )}
              <GeoCommercialStructure
                customConfig={getGeoCommercialConfig(
                  { ...messages.geographicalLevels, requiredErrorMessage: messages.addressRequiredError },
                  geographicalLevels.length,
                  true
                )}
                countryId={CountryId.Argentina}
              />
              <ControlledTextField
                id={LevelName.DeliveryStreet}
                label={messages.mainStreet}
                control={control}
                rules={formRules}
                placeholder={messages.placeholders.mainStreet}
                showAsterisk={false}
                required
              />
              <ControlledAddressNumberField
                id={LevelName.DeliveryNumber}
                label={messages.number}
                placeholder={messages.placeholders.number}
                rules={{
                  required: {
                    message: messages.addressRequiredError,
                    value: true,
                  },
                }}
                noNumberLabel={messages.checkBoxWithoutNumber}
                showAsterisk={false}
                validChars={number.validChars}
                hasNoNumberCheckbox={number?.hasNoNumberCheckbox}
              />
              <ControlledTextField
                id={LevelName.DeliveryComplement}
                label={messages.complementaryData}
                control={control}
                placeholder={messages.placeholders.complementaryData}
                optionalText={messages.optionalTextLabel}
              />
              {reference && (
                <ControlledTextField
                  id={LevelName.DeliveryReference}
                  label={messages.references}
                  control={control}
                  rules={formRules}
                  placeholder={messages.placeholders.references}
                />
              )}
            </>
          )}
        </FormProvider>
      </CardContent>
      <DialogAlert
        show={isErrorDialogOpen}
        messages={{ infoText: messages.unexpectedError, closeButtonText: messages.closeButtonText }}
        closeDialogCallback={() => setIsErrorDialogOpen(false)}
      />
    </Container>
  )
}
