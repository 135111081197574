import React from 'react'
import { Avatar, Typography } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'
import { IAvatarSizes } from '@naturacosmeticos/natds-styles'
import { TypographyVariant } from '@naturacosmeticos/natds-web/dist/Components/Typography'

export type LetterAvatarProps = {
  name: string
  dataTestId?: string
  size?: keyof IAvatarSizes
  variant?: TypographyVariant
}

const StyledTypography = styled(Typography)`
  color: #fff;
  font-weight: bolder;
`
const StyledAvatar = {
  color: '#333333',
  width: '80px',
  height: '80px',
  fontSize: '1.5rem',
  backgroundColor: '#f4ab34'
}

export const LetterAvatar: React.FC<LetterAvatarProps> = ({
  name, dataTestId = 'letter-avatar', size = 'large', variant = 'body1',
}) => {
  const getInitials = () => {
    if (!name) return name

    const uppercaseName = name.toUpperCase()

    const names = uppercaseName.trim()
      .split(' ')

    if (names.length === 1) {
      return names[0].slice(0, 2)
    }

    const first = names[0]
    const last = names[names.length - 1]

    return first.slice(0, 1) + last.slice(0, 1)
  }

  return (
    <Avatar style={StyledAvatar} color="primary" size={size}>
      <StyledTypography variant={variant} data-testid={dataTestId}>
        {getInitials()}
      </StyledTypography>
    </Avatar>
  )
}
