import React, { useContext } from 'react'
import { PersonDataResponse } from '@/domain/use-cases/person'
import { IconButton, Typography } from '@naturacosmeticos/natds-web'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Page } from '@/domain/models/page/page'
import {
  ProfileConfigs,
} from '@/domain/models/tenant/tenant-configs'
import { IdentityInfoContext } from '@/main/contexts'
import { getFormattedPhoneNumber } from '@/main/pages'
import { Icon, Tooltip } from '@/main/components'
import {
  MyProfileMessages,
} from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { CustomCard } from '../components/custom-card'
import { useContactStyles } from './contact.styles'

export type ProfileProps = {
  personData: PersonDataResponse
}

export const ProfileContact: React.FC<ProfileProps> = ({ personData: { person } }) => {
  const { emails, phones, personId } = person

  const componentClasses = useContactStyles()
  const {
    companyId,
    countryId
  } = useContext(IdentityInfoContext)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const {
    contact: {
      phoneMask: {
        maskRegex,
        mask,
      },
      editUrl,
    },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const { contact, editButton: editButtonName } = usePageMessages(Page.MyProfile).messages as MyProfileMessages

  const editButtonLink = `${editUrl}${personId}`

  return (
    <CustomCard title={contact.title} className={componentClasses.container} editButtonName={editButtonName} editButtonLink={editButtonLink}>
      <div>
        <div className={componentClasses.contactContainer}>
          <Typography variant="body2" color="textSecondary">
            {contact.phone}
          </Typography>
          {
            phones && phones.slice(0, 2).map((phone) => {
              const phoneNumber = getFormattedPhoneNumber(phone)
              const formattedPhone = phoneNumber.replace(maskRegex, mask)
              return (
                <div className={componentClasses.contactPhoneContent} key={phone.sequence}>
                  <Typography variant="body1">
                    {formattedPhone}
                  </Typography>
                  <TooltipButtonIcon
                    phone={phone}
                    formattedPhone={formattedPhone}
                    componentClasses={componentClasses}
                    title={contact.tooltipPhone}
                  />
                </div>
              )
            })
          }
        </div>

        <div>
          <Typography variant="body2" color="textSecondary" className={componentClasses.contactSubtitleSpace}>
            {contact.email}
          </Typography>
          {
            emails && emails.slice(0, 1).map(({ email, sequence }) => (
              <Typography variant="body1" key={sequence}>
                {email?.toLowerCase()}
              </Typography>
            ))
          }
        </div>
      </div>
    </CustomCard>
  )
}

const TooltipButtonIcon = ({
  phone, formattedPhone, componentClasses, title,
}) => (
  <Tooltip
    title={(
      <Typography variant="body2">
        {title}
      </Typography>
    )}
    enterTouchDelay={100}
    arrow
  >
    <IconButton
      data-testid={phone.sequence}
      className={componentClasses.contactCopyButton}
      onClick={() => navigator.clipboard.writeText(formattedPhone)}
    >
      <Icon name="outlined-action-copylink" size="tiny" />
    </IconButton>
  </Tooltip>
)
