import { BadRequestError } from '@/data/protocols/errors'
import { HttpPatchClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { AddressFormValues } from '@/main/pages/address/models'

type CompleteAddressHeaders = {
  tenantid: TenantId
  businessmodel: BUSINESS_MODELS
  functionid: FUNCTION_IDS
  role: ROLES
  'x-api-key': string,
}

export type CompleteAddressParams = {
  personId?: string
  tenantId: TenantId
  businessModel: BUSINESS_MODELS
  functionId: FUNCTION_IDS
  role: ROLES
  data: AddressFormValues
}

export class RemoteCompleteAddress {
  constructor(
    private readonly httpPatchClient: HttpPatchClient<CompleteAddressHeaders, AddressFormValues, void>,
    private readonly xApiKey: string,
  ) {}

  complete = async ({
    personId, tenantId, businessModel, functionId, role, data,
  }: CompleteAddressParams): Promise<void> => {
    const headers = {
      tenantid: tenantId,
      businessmodel: businessModel,
      functionid: functionId,
      role,
      'x-api-key': this.xApiKey,
    }

    const httpResponse = await this.httpPatchClient.patch({
      url: `/address/complete/${personId}`,
      headers,
      body: data,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
      case HttpStatusCode.ok:
        return
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
