import { MaintenanceAddressCompletedMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceAddressApprovedMessages(translate: TFunction): MaintenanceAddressCompletedMessages {
  return {
    approvedIcon: translate('maintenanceAddressApproved.approvedIcon'),
    title: translate('maintenanceAddressApproved.title'),
    subtitle: translate('maintenanceAddressApproved.subtitle'),
    description: translate('maintenanceAddressApproved.description'),
    addressLabel: translate('maintenanceAddressApproved.addressLabel'),
    statusLabel: translate('maintenanceAddressApproved.statusLabel'),
    myProfileButton: translate('maintenanceAddressApproved.myProfileButton'),
  }
}
