import { createUseStyles, Theme } from '@naturacosmeticos/natds-react'

export const useMaintenanceSecurityValidation = createUseStyles((theme: Theme) => ({
  subtitle: {
    marginTop: theme.size.semi,
    marginBottom: theme.size.semi,
  },
  emailRadioButton: {
    marginTop: theme.size.semi,
  },
}))
