import styled from 'styled-components'
import { DescriptionTexts } from '@/main/pages/confirmation/status-card.styles'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Typography } from '@naturacosmeticos/natds-web'

export const ConsultantInfoContainer = styled.div`
  margin: 12px 0;
  padding-top: 24px;
  border: 1px solid ${({ theme }) => theme.color.lowEmphasis};
  border-style: solid none;
  min-width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ConsultantCode = styled(DescriptionTexts)`
  &.consultant_code {
    margin: 20px 0;
  }
`

export const PaymentInfo = styled(Typography)`
  &.payment_info {
    margin-bottom: 24px;
  }
`

export const Image = styled.img`
  max-height: 56px;
`

export const AppBadgeWrapper = styled.div`
  max-height: 56px;
`

const getColor = (theme, footerColor: string) => {
  try {
    return footerColor.split('.').reduce((obj, i) => obj[i], theme)
  } catch (e) {
    return theme.color.secondaryLight
  }
}

export const Footer = styled.div<{ footerColor?: string }>`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 0px;

  margin-top: 28px;
  padding: 32px 0;
  width: 100%;
  background-color: ${({ theme, footerColor }) => getColor(theme, footerColor)};
  box-sizing: border-box;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    grid-template-columns: 0.5fr 1.5fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 12px;
    padding: 32px 60px;
  }
`

export const FooterDescripton = styled(DescriptionTexts)`
  max-width: 112px;
  text-align: start;
`

export const IndicationContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.background};
  padding: ${({ theme }) => theme.sizes.standard}px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DigitalStoreContainer = styled.div`
  display: block;
`

export const DigitalStoreDescription = styled.div`
  padding: 0 24px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    padding: 0 64px;
  }

  p {
    margin: 1rem 0;
  }

  .text-link {
    margin-top: -1rem;
    font-weight: bold;
    word-break: break-word;
  }
`
