import { useEffect, useState } from 'react'
import { buildTheme, Theme } from '@naturacosmeticos/natds-react'
import { CompanyId } from '@/domain/models/company/company-id'
import { companyNameById } from '@/domain/models/company/company-name-by-id'
import { ColorScheme } from '@/domain/models/theme-ds/color-scheme'
import { consultoriaDeBeleza_light_full_theme } from '@/mock/ConsultoriaDeBeleza_mock/theme_consultoriaDeBeleza_mock_light'
import { getEloToggleByCountry } from '@/utils/getEloToggle'

export const useFVTheme = (companyId: CompanyId): Theme => {
  const company = companyNameById[companyId]
  const defaultTheme = buildTheme(company, ColorScheme.LIGHT)
  const [fvTheme, setTheme] = useState<Theme>(defaultTheme)
  

  useEffect(() => {
    const newTheme = buildTheme(company, ColorScheme.LIGHT)
    setTheme( getEloToggleByCountry() ? consultoriaDeBeleza_light_full_theme : newTheme)
  }, [company])

  return fvTheme
}
